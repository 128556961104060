<template>
    <div class="d-flex flex-column gap-4">
        <base-input-floating
            :label="$t('widgets.champion.subTitle')"
            :formErrors="errors"
            :rules="{required: false, max: 255}"
            name="preTitleText"
            v-model="widget.content.subTitle"/>

        <WidgetIntervalSelect
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>

        <WidgetColorSelect
            v-model="widget"
            :errors="errors"
            @update:data="getWidgetData()"
            :exclude-colors="['white']"/>

        <div class="border-bottom"/>

        <WidgetUserSelect
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>

        <div class="border-bottom"/>

        <WidgetEventTypeSelect
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>

        <div class="border-bottom"/>

        <WidgetDataDisplayOption
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            :options="['sum', 'amount', 'topEvent']"
            @update:data="getWidgetData()"/>
    </div>
</template>

<script setup>
import BaseInputFloating from "~/components/farbcode-core/Inputs/BaseInputFloating.vue";
import WidgetUserSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetUserSelect.vue";
import {getData} from "~/composables/widget";
import {useDashboardStore} from "~/store/dashboard";
import WidgetEventTypeSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetEventTypeSelect.vue";
import WidgetDataDisplayOption from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetDataDisplayOption.vue";
import WidgetIntervalSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetIntervalSelect.vue";
import WidgetColorSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetColorSelect.vue";

const dashboardStore = useDashboardStore()

const props = defineProps({
    nodeId: {
        required: true,
        type: Number,
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    modelValue: {
        required: true,
        type: Object,
    },
    organisationId: {
        required: true,
        type: Number,
    }
})

const widget = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

const emit = defineEmits(['update:modelValue'])

function getWidgetData() {
    getData(props.nodeId, dashboardStore.dashboard.token, 'champion')
}
</script>
<template>
    <div class="d-flex flex-column gap-5">
        <ImageUpload v-model="widget.content.image"/>
        <div class="d-flex flex-column">
            <p class="fw-bold">{{ $t('widgets.image.displayType') }}</p>

            <v-field name="displayType" :value="widget.content.displayType" :formErrors="errors">
                <div class="form-check" v-for="type in types">
                    <input class="form-check-input" type="radio" :name="type.value" :id="type.value"
                            :value="type.value" v-model="widget.content.displayType">
                    <label class="form-check-label" :for="type.value">
                        {{ type.label }}
                    </label>
                </div>
            </v-field>
        </div>
    </div>
</template>

<script setup>
import ImageUpload from "@/components/farbcode-core/Inputs/ImageUpload";

const {t} = useI18n()

const props = defineProps({
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    modelValue: {
        required: true,
        type: Object,
    }
})

const types = [
    {value: 'contain', label: t('widgets.image.displayTypes.contain')},
    {value: 'cover', label: t('widgets.image.displayTypes.cover')},
]

const emit = defineEmits(['update:modelValue'])

const widget = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})




</script>


<template>
    <div class="sidebar" :class="{'pt-4': displayUpgradeBadge()}">
        <nuxt-link v-if="displayUpgradeBadge()" to="/organisation/subscriptions" class="upgradeInfo text-decoration-none p-1 text-center bg-primary cursor-pointer">
            {{ $t('widgets.upgradePlanBadge') }}
        </nuxt-link>
        <div class="content p-4">
            <div v-if="userHasMobileDevice" class="d-flex py-4 px-3 mb-3 bg-opacity-10 bg-secondary rounded-1">
                <font-awesome-icon :icon="['fas', 'circle-info']" class="me-3 mt-1"/>
                <span>{{ $t('dashboards.notEditableOnMobile') }}</span>
            </div>
            <div class="row py-2" :class="{'opacity-25 pe-none':userHasMobileDevice}">
                <p class="text-muted" v-if="isCreate">{{ $t('dashboards.createBeforeAddingWidgetsInfo') }}</p>
                <div v-else>
                    <input type="text" class="form-control mb-3" :placeholder="$t('widgets.search')" v-model="search">
                </div>

                <div v-for="(category, index) in categories">
                    <div class="py-4 d-flex flex-column gap-3">
                        <p @click="category.toggleShow()" class="cursor-pointer user-select-none mb-0 h5 fw-bold">
                            <font-awesome-icon :icon="category.show.value ? 'caret-down' : 'caret-right'" class="pe-2"/>
                            {{ $t(`widgets.categories.${category.category}`) }}
                        </p>
                        <div v-if="category.show.value" class="d-flex py-2 gap-2 me-2">
                            <div
                                v-for="(widget) in category.widgets.value"
                                class="grid-stack-item col-6 gap-2"
                                :style="{cursor: notDraggable(widget) ? 'not-allowed' : 'move'}"
                                :data-widget-type="widget.type"
                                :gs-w="widget.defaultConfig.w"
                                :gs-h="widget.defaultConfig.h">
                                <div class="grid-stack-item-content p-2 db-shadow-sm rounded-2 h-100 w-100" :class="{'pe-none user-select-none text-muted': notDraggable(widget)}">
                                    <div class="d-flex flex-column justify-content-center align-items-center p-4 h-100 gap-3 bg-white">
                                        <font-awesome-icon v-if="widget.icon" :icon="widget.icon" style="height: 40px; width: 40px"/>
                                        <p class="text-center mb-0">{{ $t(`widgets.${widget.type}.name`) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="index < categories.length - 1" class="border-bottom"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {GridStack, Utils} from 'gridstack';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {isMobileDevice} from "~/composables/userDevice";
import {useAbility} from "@casl/vue";

import {textData} from '~/utils/widgets/text';
import {imageData} from '~/utils/widgets/image';
import {rankingData} from '~/utils/widgets/ranking';
import {goalData} from "~/utils/widgets/goal";
import {goalOverviewData} from "~/utils/widgets/goalOverview";
import {championData} from "~/utils/widgets/champion";
import {currentData} from "~/utils/widgets/current";
import {latestData} from "~/utils/widgets/latest";

import { useI18n } from 'vue-i18n';

const route = useRoute()
const { t } = useI18n();
const config = useRuntimeConfig()
const { can } = useAbility();

const isCreate = computed(() => route.params.id === '0');

const userHasMobileDevice = computed(() => {
    return isMobileDevice()
})

const sidebarWidgets = [
    textData,
    rankingData,
    goalData,
    goalOverviewData,
    currentData,
    latestData,
    imageData,
    championData
];

function notDraggable(widget) {
    return isCreate.value || !can(widget.type, 'access-widgets');
}

function displayUpgradeBadge() {
    return !can('can_use_premium_widgets', 'access-widgets') && !userHasMobileDevice.value
}

const availableWidgets = computed(() => {
    return sidebarWidgets.filter(widget => {
        // Check if the widget is disabled in the config
        return config.public.features[`${widget.type}WidgetEnabled`] !== false;
    })
})

const search = ref('');

const categories = computed(() => {
    // automatically detect all available categories
    const categories = availableWidgets.value.reduce((acc, widget) => {
        if (!acc.includes(widget.category)) acc.push(widget.category);
        return acc;
    }, []);
    return categories.map(category => {
        return {
            category,
            show: ref(true),
            toggleShow() {
                this.show.value = !this.show.value
            },
            widgets: computed(() => {
                return filteredWidgets.value.filter(widget => widget.category === category);
            })
        }
    })
})

const filteredWidgets = computed(() => {
    if (!search.value) return availableWidgets.value;
    let filteredWidgets = availableWidgets.value.filter(widget => {
        return t(`widgets.${widget.type}.name`).toLowerCase().startsWith(search.value.toLowerCase());
    })
    if (filteredWidgets.length === 0) {
        filteredWidgets = availableWidgets.value.filter(widget => {
            return t(`widgets.${widget.type}.name`).toLowerCase().includes(search.value.toLowerCase());
        })
    }
    return filteredWidgets
});

onMounted(() => {
    if (isCreate.value) return;
    GridStack.setupDragIn('.grid-stack-item', {
        /**
         * @param event {Partial<DragEvent>}
         */
        helper: (event) => {
            //generate random id
            const id = 'rand' + Math.floor(Math.random() * 10000)
            const target = event.target
            const correctTarget = target.closest('.grid-stack-item')
            correctTarget.setAttribute('gs-id', id);
            return Utils.cloneNode(correctTarget);
        }
    });
});
</script>

<style scoped>
.sidebar {
    position: relative;
}

.upgradeInfo {
    position: absolute;
    top: 0;
    width: 100%;
}
</style>
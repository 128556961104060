<template>
    <div class="h-100 d-flex flex-column">
        <div class="p-4 d-flex flex-column gap-4">
            <div v-if="linkedDashboards?.length === 0">
                {{ $t('dashboards.sidebar.navigation.noLinkedDashboards') }}
            </div>
            <div class="d-flex py-4 px-4 bg-opacity-10 bg-secondary rounded-1"
                 v-if="!isDashboardPage">
                <span class="text-sm">{{ $t('dashboards.sidebar.navigation.favoriteDashboardExplanation') }}</span>
            </div>
            <nuxt-link
                v-for="dashboard in linkedDashboards"
                :to="(isDashboardPage) ?
                localePath({name: 'dashboards-token-token', params: {token: dashboard.token}}) :
                localePath({query: {token: dashboard.token}})"
                class="position-relative p-4 db-shadow-sm rounded-2 col-6 w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center text-decoration-none"
                :class="[{'bg-primary' : dashboard.token === dashboardToken},
                {'text-gray pe-none' : !dashboard.is_public && !authStore.isAuthenticated},
                {'opacity-75 pe-none': dashboardStore.hasLoadingWidgets}],
                {'pe-none' : setFavoriteLoading}">
                <img
                    :src="(dashboard.icon_name) ? `/b-icons/${dashboard.icon_name}.svg` : '/dealbuzzer_logo_icon_black.svg'"
                    :style="`${(dashboard.icon_name) ? 'height: 55px; width: 55px;' : ' ? height: 60px; width: 60px;'}`"
                    :class="{'img-muted' : !dashboard.is_public && !authStore.isAuthenticated}"
                    alt="icon"/>
                <div>
                    <p class="mb-0 fw-semibold">{{ dashboard.name }}</p>
                    <small>
                        {{
                            dashboard.is_public ? $t('dashboards.public') :
                                $t('dashboards.private')
                        }}
                    </small>
                </div>
                <div v-if="!isDashboardPage" class="position-absolute top-0 end-0 p-3">
                    <Tooltip :content="isFavorite(dashboard) ? $t('dashboards.setFavorite') : $t('dashboards.removeFavorite')">
                        <div @click.prevent="setFavorite(dashboard.id)">
                            <font-awesome-icon
                                :icon="setFavoriteLoading ? 'fa-circle-notch' : ['far', 'star']"
                                :spin="setFavoriteLoading"/>
                            <div
                                class="position-absolute top-0 end-0 p-3 transition-100"
                                :class="{'opacity-0 opacity-hover-100' : isFavorite(dashboard)}">
                                <font-awesome-icon
                                    :icon="setFavoriteLoading ? 'fa-circle-notch' : ['fas', 'star']"
                                    :spin="setFavoriteLoading"/>
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </nuxt-link>
        </div>
    </div>
</template>

<script setup>
import {useDashboardStore} from "@/store/dashboard";
import {useAuthStore} from "@/store/auth";

const isDashboardPage = computed(() => route.name.includes('dashboards'))

const dashboardToken = computed(() => {
    if (isDashboardPage.value) {
        return route.params.token
    } else if (route.query?.token) {
        return route.query.token
    } else if (authStore.homeDashboards.favorite) {
        return authStore.homeDashboards.favorite.token
    } else {
        return authStore.homeDashboards.all[0].token
    }
})
const linkedDashboards = computed(() => {
    if (isDashboardPage.value) {
        return dashboardStore.linkedDashboards
    } else {
        return authStore.homeDashboards.all
    }
})

const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
authStore.getAuthData(true) // true to prevent redirect even if not authenticated
const route = useRoute()
const setFavoriteLoading = ref(false)

function setFavorite(id) {
    setFavoriteLoading.value = true
    $larafetch(useNuxtApp().$apiRoute('spa.dashboards.update', {dashboard: id}), {
        method: 'PUT',
        params: {
            updateMode: 'setFavorite'
        }
    }).then(response => {
        authStore.homeDashboards.favorite = response
    }).catch(error => {
        toast.error(error.data.message)
    }).finally(() => {
        setFavoriteLoading.value = false
    })
}

function isFavorite(dashboard) {
    return dashboard.id !== authStore.homeDashboards.favorite?.id
}
</script>


<template>
    <div class="d-flex flex-column gap-4">
        <base-input-floating
            :label="$t('widgets.text.preTitle')"
            :formErrors="errors"
            :rules="{required: false, max: 255}"
            name="preTitleText"
            v-model="widget.content.preTitle"/>

        <base-input-floating
            :label="$t('widgets.text.mainTitle')"
            :formErrors="errors"
            :rules="{required: false, max: 255}"
            name="mainTitleText"
            v-model="widget.content.mainTitle"/>

        <textarea
            class="rounded-2 p-2 form-control"
            v-model="widget.content.text"
            :placeholder="$t('widgets.text.text')"
            name="textText"
            :id="$t('widgets.text.text')"
            cols="30"
            rows="10"/>

        <WidgetColorSelect
            v-model="widget"
            :errors="errors"
            @update:data="getWidgetData()"/>

        <div class="d-flex flex-column">
            <p>{{ $t('widgets.text.textAlignment') }}</p>

            <v-field name="textAlignment" :value="widget.content.textAlignment" :formErrors="errors">
                <div class="form-check" v-for="alignment in alignments">
                    <input class="form-check-input" type="radio" :name="alignment.value" :id="alignment.value"
                           :value="alignment.value" v-model="widget.content.textAlignment">
                    <label class="form-check-label" :for="alignment.value">
                        {{ alignment.label }}
                    </label>
                </div>
            </v-field>
        </div>
    </div>
</template>

<script setup>
import BaseInputFloating from "~/components/farbcode-core/Inputs/BaseInputFloating.vue";
import WidgetColorSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetColorSelect";

const {t} = useI18n()

const alignments = [
    {value: 'start', label: t('widgets.text.alignments.start')},
    {value: 'center', label: t('widgets.text.alignments.center')},
    {value: 'end', label: t('widgets.text.alignments.end')},
]

const props = defineProps({
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    modelValue: {
        required: true,
        type: Object,
    }
})

const emit = defineEmits(['update:modelValue'])

const widget = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})
</script>


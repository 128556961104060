<template>
    <div class="sidebar-wrapper pe-none" :class="[sidebarState, {'overlay' : overlay}]">
        <div class="sidebar d-flex flex-column db-drop-shadow">
            <div class="sidebar-animation d-flex h-100 justify-content-end align-items-center position-relative">
                <transition name="left-to-right" mode="out-in">
                    <div
                        v-show="sidebarStore.isSidebarOpen"
                        class="position-relative bg-white flex-grow-1 h-100 pe-auto overflow-auto sidebar-content">
                        <div class="position-absolute top-0 end-0 d-flex justify-content-end z-index-1">
                            <button
                                type="button"
                                class="d-sm-none border-0 bg-transparent m-4 p-0"
                                @click="sidebarStore.isSidebarOpen = false">
                                <span class="sr-only">Close sidebar</span>
                                <font-awesome-icon icon="fa-solid fa-xmark" aria-hidden="true" size="lg"/>
                            </button>
                        </div>
                        <Component :is="components[sidebarStore.component]"/>
                        <slot/>
                    </div>
                </transition>
                <div
                    class="sidebar-toggle position-absolute align-items-center justify-content-center pe-auto cursor-pointer transition-colors"
                    :class="[sidebarStore.isSidebarOpen ? 'bg-white text-gray' : 'bg-primary text-black']"
                    @click="toggleLayoutStore()">
                    <font-awesome-icon :icon="(sidebarStore.isSidebarOpen) ? 'chevron-left' : 'chevron-right'"/>
                    <font-awesome-icon :icon="(sidebarStore.isSidebarOpen) ? 'chevron-left' : 'chevron-right'"
                                       style="margin-left: -3px"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useSidebarStore} from "@/store/sidebar";
import DashboardSidebarContent from "@/components/pages/dashboards/DashboardSidebarContent";
import HomeSidebarContent from "@/components/pages/home/HomeSidebarContent";
import DashboardSidebarWidgetEdit from '@/components/pages/dashboards/DashboardWidgetEdit';
import DashboardNavigation from "@/components/pages/dashboards/DashboardNavigation";

const props = defineProps({
    overlay: {
        type: Boolean,
        default: false
    },
})

const components = {DashboardSidebarContent, HomeSidebarContent, DashboardSidebarWidgetEdit, DashboardNavigation}

const sidebarStore = useSidebarStore()
const sidebarState = computed(() => {
    return sidebarStore.isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'
})

function toggleLayoutStore() {
    sidebarStore.isSidebarOpen = !sidebarStore.isSidebarOpen
}
</script>

<style scoped>

</style>
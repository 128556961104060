<template>
    <div class="h-100 d-flex flex-column">
        <div class="p-4 border-bottom border-dark position-relative">
            <nuxt-link :to="localePath({name: '#'})"
                       @click="leaveEdit()"
                        class="cursor-pointer">
                <div class="position-absolute top-50 translate-middle-y">
                    <font-awesome-icon
                        icon="right-to-bracket"
                        size="lg"
                        style="transform: scaleX(-1)" />
                </div>
            </nuxt-link>
            <h2 class="h4 px-5 mx-5 px-sm-0 fw-semibold text-center mb-0 d-flex align-items-center justify-content-center gap-2">
                <img style="height: 20px" :src="(dashboardStore.dashboard.icon_name) ? `/b-icons/${dashboardStore.dashboard.icon_name}.svg` : '/dealbuzzer_logo_icon_black.svg'" alt="icon"/>
                {{ dashboardStore.dashboard.name }}</h2>
        </div>
        <div v-if="sidebarStore.viewMode">
            <DashboardNavigation />
        </div>
        <div class="row justify-content-start tabs-wrapper sidebar-tabs m-0" v-else>
            <div
                class="col-6 d-flex justify-content-center cursor-pointer tab bg-white text-decoration-none"
                :class="{'tab-active' : (sidebarStore.activeTab === 'DashboardSettings')}"
                @click="sidebarStore.activeTab = 'DashboardSettings'">
                {{ $t('dashboards.sidebar.general.settings') }}
            </div>
            <div
                class="col-6 d-flex justify-content-center cursor-pointer tab bg-white text-decoration-none"
                :class="{'tab-active' : (sidebarStore.activeTab === 'DashboardWidgets')}"
                @click="sidebarStore.activeTab = 'DashboardWidgets'">
                {{ $t('dashboards.sidebar.general.widgets') }}
            </div>
        </div>
        <div class="invisible-scrollbar-container h-100" :style="{'margin-bottom': sidebarStore.activeTab === 'DashboardSettings' ? '80px' : '0'}">
            <v-form v-slot="{ errors }" @submit="dashboardStore.saveDashboard" ref="formValidator">
                <Component  :is="components[sidebarStore.activeTab]" :errors="errors"/>
                <div v-if="!sidebarStore.viewMode && sidebarStore.activeTab === 'DashboardSettings'" class="position-fixed bottom-0 start-0 w-100 p-4 bg-white">
                    <button :disabled="submitLoading" class="btn btn-primary btn-block db-shadow"
                            native-type="submit">
                        <span v-if="!submitLoading">{{ $t('dashboards.sidebar.saveDashboard') }}</span>
                        <span v-else><font-awesome-icon spin icon="circle-notch"/>&nbsp;{{ $t('common.pleaseWait') }}</span>
                    </button>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script setup>
import {useSidebarStore} from "@/store/sidebar";
import {useDashboardStore} from "@/store/dashboard";
import DashboardWidgets from "@/components/pages/dashboards/DashboardWidgets";
import DashboardSettings from "@/components/pages/dashboards/DashboardSettings";
import DashboardNavigation from "~/components/pages/dashboards/DashboardNavigation.vue";
import Swal from "sweetalert2";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const sidebarStore = useSidebarStore()
const dashboardStore = useDashboardStore()

const dashboard = computed(() => (dashboardStore.dashboard))
const components = {DashboardWidgets, DashboardSettings}

const submitLoading = computed(() => dashboardStore.submitLoading)

async function leaveEdit() {
    if (dashboard.value.is_dirty || dashboardStore.hasDirtyWidgets) {
        await Swal.fire({
            title: t('dashboards.unsavedChangesTitle'),
            text: t('dashboards.unsavedChangesText'),
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-secondary me-2'
            },
            reverseButtons: true,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText: t('common.discard.discardWithName', {name: t('common.changes')}),
            confirmButtonText: t('common.saveWithName', {name: t('dashboard')}),
        }).then(async (result) => {
            if (result.isConfirmed) {
                await dashboardStore.saveDashboard()
                navigateTo({name: 'dashboards'})
            } else if (result.isDismissed && result.dismiss === 'cancel') {
                navigateTo({name: 'dashboards'})
            }
        })
    } else {
        navigateTo({name: 'dashboards'})
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="d-flex flex-column">
        <div class="d-flex flex-column">
            <div class="form-floating">
                <v-field
                    v-if="widget?.content"
                    v-model="widget.content.interval"
                    name="interval"
                    :label="$t('widgets.interval.title')"
                    v-slot="{ field, errors, errorMessage, meta}">
                    <label class="floating-label mb-1" for="interval">
                        {{ $t('widgets.interval.title') }}
                    </label>
                    <div v-if="staticsLoading"
                         class="form-control border-0">
                        <font-awesome-icon
                            :icon="'fa-circle-notch'"
                            :spin="true"
                            size="sm"/>
                    </div>
                    <select v-else
                            v-model="widget.content.interval"
                            class="form-control text-muted"
                            id="interval"
                            @change="$emit('update:data')">
                        <option v-for="option in statics.intervals" :value="option">
                            {{ t('common.timeFormat.' + option) }}
                        </option>
                    </select>
                    <font-awesome-icon icon="fa-chevron-down" class="position-absolute top-50 end-0"/>
                </v-field>
            </div>

        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const props = defineProps ({
    modelValue: {
        required: true,
        default: () => {}
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    }
})

const emits = defineEmits(['update:data'])

const {data:statics, pending:staticsLoading} = useAsyncData('statics', () => $larafetch(useNuxtApp().$apiRoute('spa.statics.index'), {
    method: 'GET'
}).catch(errors => {
    console.log(errors)
    return {data: []}
}))

const widget = computed(() => props.modelValue)
</script>
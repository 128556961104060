<template>
    <div class="d-flex flex-column">
        <p>{{ $t('widgets.text.color') }}</p>

        <v-field name="color" :value="widget.content.color" :formErrors="errors">
            <div class="form-check" v-for="color in colors">
                <input class="form-check-input" type="radio" :name="color.value" :id="color.value"
                        :value="color.value" v-model="widget.content.color">
                <label class="form-check-label" :for="color.value">
                    {{ color.label }}
                </label>
            </div>
        </v-field>
    </div>
</template>

<script setup>
const {t} = useI18n()

const colors = computed(() => {
    return [
        {value: 'black', label: t('widgets.text.colors.black')},
        {value: 'white', label: t('widgets.text.colors.white')},
        {value: 'primary', label: t('widgets.text.colors.primary')},
    ].filter(color => !props.excludeColors.includes(color.value))
})

const props = defineProps ({
    modelValue: {
        required: true,
        default: () => {}
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    excludeColors: {
        required: false,
        type: Array,
        default: () => []
    }
})

const emits = defineEmits(['update:data'])

const widget = computed(() => props.modelValue)
</script>
<template>
    <div class="d-flex flex-column">
        <div class="d-flex flex-column">
            <div class="form-floating">
                <base-input-floating
                    v-if="widget?.content"
                    :rules="{required: false, min_value:1}"
                    :formErrors="errors"
                    type="number"
                    :min="1"
                    :label="$t('widgets.limit.title')"
                    name="limit"
                    v-model="widget.content.limit"
                    @change="$emit('update:data')">
                </base-input-floating>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const props = defineProps ({
    modelValue: {
        required: true,
        default: () => {}
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    }
})

const emits = defineEmits(['update:data'])

const widget = computed(() => props.modelValue)
</script>
<template>
    <div class="h-100 d-flex flex-column">
        <div class="p-4 border-bottom border-dark position-relative">
            <nuxt-link :to="localePath({name: '#'})"
                        @click="leaveEdit(widget)"
                        class="cursor-pointer">
                <div class="position-absolute top-50 translate-middle-y">
                    <font-awesome-icon
                        :icon="['fas', 'arrow-left']"
                        size="lg" />
                </div>
            </nuxt-link>
            <h2 class="h4 px-5 px-sm-0 fw-semibold text-center mb-0 flex-grow-1">{{ $t('widgets.editTitle') }}</h2>
        </div>
        <div class="p-4 invisible-scrollbar-container h-100" style="margin-bottom: 80px">
            <v-form v-slot="{ errors, isSubmitting }" ref="widgetForm" @submit="onSubmit">
                <Component
                    :is="components[widget.type]"
                    v-model="widget"
                    v-model:submit-disabled="submitDisabled"
                    :errors="errors"
                    :organisationId="dashboard.organisation_id"
                    :nodeId="selectedWidgetNodeId"/>
                <div class="position-fixed bottom-0 start-0 w-100 p-4 bg-white">
                    <button :disabled="submitDisabled || isSubmitting" class="btn btn-primary btn-block db-shadow">
                        <span v-if="!isSubmitting">{{ $t('dashboards.sidebar.saveWidget') }}</span>
                        <span v-else><font-awesome-icon spin icon="circle-notch"/>&nbsp;{{ $t('common.pleaseWait') }}</span>
                    </button>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script setup>
import Swal from "sweetalert2";
import {useSidebarStore} from "@/store/sidebar";

import TextForm from '@/components/pages/dashboards/widgets/Text/TextForm.vue';
import ImageForm from '@/components/pages/dashboards/widgets/Image/ImageForm.vue';
import RankingForm from '@/components/pages/dashboards/widgets/Ranking/RankingForm.vue';
import GoalForm from "~/components/pages/dashboards/widgets/Goal/GoalForm.vue";
import GoalOverviewForm from "./widgets/GoalOverview/GoalOverviewForm.vue";
import CurrentForm from "~/components/pages/dashboards/widgets/Current/CurrentForm.vue";
import LatestForm from "./widgets/Latest/LatestForm.vue";
import ChampionForm from "~/components/pages/dashboards/widgets/Champion/ChampionForm.vue";

import {useDashboardStore} from "@/store/dashboard";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {useWidget} from "~/composables/widget";

import {useToast} from "vue-toastification";
import {storeToRefs} from "pinia";
import {isMobileDevice} from "~/composables/userDevice";

const route = useRoute()
const {$apiRoute} = useNuxtApp()
const {t} = useI18n()
const toast = useToast()

const components = {
    text: TextForm,
    image: ImageForm,
    ranking: RankingForm,
    goal: GoalForm,
    goalOverview: GoalOverviewForm,
    champion: ChampionForm,
    current: CurrentForm,
    latest: LatestForm,
}

const submitDisabled = ref(false)

const sidebarStore = useSidebarStore()
const {dashboard, selectedWidgetNodeId, persistedWidgets} = storeToRefs(useDashboardStore())

const widgetForm = ref(null)
function leaveEdit(widget) {
    if (widget.is_new) {
        Swal.fire({
            title: t('widgets.newWidgetNotSavedTitle'),
            text: t('widgets.newWidgetNotSavedText'),
            customClass: {
                confirmButton: 'btn btn-secondary me-2',
                cancelButton: 'btn btn-primary'
            },
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText: t('common.save'),
            confirmButtonText: t('common.discard.discardWithName', {name: `Widget \"${t(`widgets.${widget.type}.name`)}\"`}),
        }).then(async (result) => {
            if (result.value) {
                useEventBus('widgets:discard').emit(widget)
            } else {
                await onSubmit(widgetForm.value.getValues())
            }
        }).finally(() => {
            reset()
        })
    } else if (widget.is_dirty) {
        Swal.fire({
            title: t('widgets.dirtyWidgetNotSavedTitle'),
            text: t('widgets.dirtyWidgetNotSavedText'),
            customClass: {
                confirmButton: 'btn btn-secondary me-2',
                cancelButton: 'btn btn-primary'
            },
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText: t('common.save'),
            confirmButtonText: t('common.discard.discardChanges'),
        }).then(async (result) => {
            if (result.value) {
                const persistedWidget = persistedWidgets.value.find(w => w.id === widget.id)
                const targetWidget = dashboard.value.widgets.find(w => w.id === widget.id)
                targetWidget.content = structuredClone(toRaw(persistedWidget.content))
                targetWidget.config = structuredClone(toRaw(persistedWidget.config))
                await getData(widget.node_id, dashboard.value.token, widget.type, true)
            } else {
                await onSubmit(widgetForm.value.getValues())
            }
        }).finally(() => {
            reset()
        })
    } else {
        reset()
    }
}

function reset() {
    sidebarStore.component = 'DashboardSidebarContent'
    sidebarStore.activeTab = 'DashboardSettings'
    selectedWidgetNodeId.value = null
}

const widget = useWidget(selectedWidgetNodeId)

async function onSubmit (values, actions = {}) {
    const isCreate = widget.value.is_new
    const apiRoute = isCreate ? $apiRoute('spa.widgets.store') : $apiRoute('spa.widgets.update', {widget: widget.value.id})

    const body = {
        userHasMobileDevice: isMobileDevice(),
        config: widget.value.config,
        content: widget.value.content,
        type: widget.value.type,
        dashboard_id: dashboard.value.id,
    }

    if (useRoute().query?.mode  === 'template') {
        body['updateMode'] = 'template'
    }
    await $larafetch(apiRoute, {
        method: isCreate ? 'POST' : 'PUT',
        body: body
    }).then((response) => {
        persistedWidgets.value = persistedWidgets.value.map(w => {
            if (w.id === widget.value.id) {
                return structuredClone(response)
            }
            return w
        })
        swapSelectedWidget(widget.value, structuredClone(response))
        toast.success(t('widgets.saveSuccess'))
        leaveEdit(widget)
    }).catch((e) => {
        if (e.data?.message) {
            toast.error(e.data.message)
        } else {
            toast.error(t('widgets.saveError'))
        }
        if (actions && typeof actions.setErrors === 'function') {
            actions.setErrors(e.data?.errors)
        } else {
            console.log('setErrors is not a function')
        }
    })
}

function swapSelectedWidget(oldWidget, newWidget) {
    dashboard.value.widgets = dashboard.value.widgets.map((w) => {
        if (w.id === oldWidget.id) {
            // swap the old widget with the new widget, keeping the node_id
            return {
                ...newWidget,
                node_id: oldWidget.node_id,
                data: oldWidget.data,
                is_dirty: false,
            }
        }
        return w
    })
}

watch(() => widget.value, () => {
    widget.value.is_dirty = true
}, {deep:true})

</script>

<style scoped>

</style>
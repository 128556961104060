<template>
    <div class="d-flex flex-column gap-4">
        <base-input-floating
            :label="$t('widgets.current.title')"
            :formErrors="errors"
            :rules="{required: false, max: 255}"
            name="titleCurrent"
            v-model="widget.content.title"/>

        <WidgetIntervalSelect
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>

        <WidgetColorSelect
            v-model="widget"
            :errors="errors"
            @update:data="getWidgetData()"/>

        <div class="border-bottom"></div>

        <WidgetUserSelect
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>

        <div class="border-bottom"></div>

        <WidgetEventTypeSelect
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>

        <div class="border-bottom"></div>

        <WidgetDataDisplayOption
            v-model="widget"
            :errors="errors"
            :organisationId="organisationId"
            @update:data="getWidgetData()"/>
    </div>
</template>

<script setup>
import {useDashboardStore} from '~/store/dashboard';
import {getData} from "~/composables/widget";
import BaseInputFloating from "~/components/farbcode-core/Inputs/BaseInputFloating.vue";
import WidgetUserSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetUserSelect";
import WidgetEventTypeSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetEventTypeSelect";
import WidgetDataDisplayOption from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetDataDisplayOption";
import WidgetIntervalSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetIntervalSelect";
import WidgetColorSelect from "~/components/pages/dashboards/widgets/WidgetSettings/WidgetColorSelect";

const dashboardStore = useDashboardStore()
const {t} = useI18n()

const props = defineProps({
    nodeId: {
        required: true,
        type: Number,
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    modelValue: {
        required: true,
        type: Object,
    },
    organisationId: {
        required: true,
        type: Number,
    }
})

const emit = defineEmits(['update:modelValue'])

const widget = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
})

function getWidgetData() {
    checkIfAuthUserSelected()
    getData(props.nodeId, dashboardStore.dashboard.token, 'current')
}

function checkIfAuthUserSelected() {
    if (widget.value.content.userSelect === 'authenticated') {
        widget.value.content.startPosition = 1
    }
}
</script>


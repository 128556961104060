<template>
    <div>
        <div style="height: 150px; overflow: hidden" class="border rounded-2 d-flex justify-content-center align-items-center">
            <img v-if="imageLocation" :src="imageLocation"/>
            <font-awesome-icon v-else :icon="['fas', 'image']" class="opacity-25" style="width: 100px; height: 100px"/>
        </div>
        <div class="btn-group db-shadow border-top-0" role="group">
            <label type="button">
                <div class="btn btn-primary border-top-0">{{ buttonTitle }}</div>
                <input class="d-none" type="file"
                       @change="uploadHandler($event)"
                       accept="image/png, image/jpeg, image/gif, image/bmp, image/webp">
            </label>
            <button type="button" class="btn btn-secondary border-top-0"
                    :disabled="!modelValue"
                    @click="clearImage()">
                {{ $t('widgets.image.removeImage') }}
            </button>
        </div>

    </div>
</template>
<script setup>
const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['update:modelValue'])

const image = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})

const {t} = useI18n()

const buttonTitle = computed(() => {
    return props.modelValue ? t('widgets.image.changeImage') : t('widgets.image.uploadImage')
})

let oldImage = null

function uploadHandler(e) {
    let file = e.target.files[0];

    if (file !== undefined) {
        let reader = new FileReader();
        if (props.modelValue) {
            if (props.modelValue.includes('original')) {
                oldImage = props.modelValue;
            }
        }
        reader.onloadend = function () {
            // Emit the new image data
            image.value = reader.result;
        };
        reader.readAsDataURL(file);
    } else {
        image.value = null;
    }
}

function clearImage() {
    oldImage = props.modelValue;
    image.value = null;
}

const imageLocation = computed(() => {
    if (props.modelValue?.includes('uploads')) {
        return '/storage/' + props.modelValue;
    } else if (props.modelValue?.includes('base64')) {
        return props.modelValue;
    }
})
</script>
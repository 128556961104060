<template>
    <div class="d-flex flex-column">
        <p @click="toggleShow()" class="cursor-pointer user-select-none mb-0 h5 fw-bold">
            <font-awesome-icon :icon="(showContent) ? 'caret-down' : 'caret-right'" class="pe-2"/>
            {{ $t('widgets.eventTypeSelect.title') }}
        </p>

        <div v-if="showContent" class="d-flex flex-column gap-3 mt-3 pt-2">
            <div v-if="useRoute().query?.mode === 'template'">
                -> {{ $t('widgets.eventTypeSelect.templateMode') }}
            </div>

            <div v-else-if="loading" class="mt-2">
                <font-awesome-icon  spin icon="fa-circle-notch" class="me-2"/>
                <span>{{ $t('common.loading') }}</span>
            </div>

            <v-field
                v-else-if="Object.values(eventTypes)?.length > 0"
                name="eventTypes"
                :value="widget?.content.eventTypes"
                :formErrors="errors">
                <div class="form-check d-flex flex-column justify-content-center gap-3">
                    <div class="mt-2">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="eventTypes"
                            :checked="allEventTypesActive"
                            @change="toggleEventTypesActive()">
                        <label for="eventTypes">
                            {{ $t('widgets.eventTypeSelect.selectAll') }}
                        </label>
                    </div>
                    <ul class="ps-0 list-unstyled mb-0">
                        <li v-for="eventType in eventTypes">
                            <div class="d-flex align-items-center gap-2">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :name="'eventType' + eventType.id"
                                    :id="'eventType' + eventType.id"
                                    :checked="widget.content.eventTypes?.includes(eventType.id)"
                                    @change="toggle(eventType.id)">
                                <label class="form-check-label" :for="'eventType' + eventType.id">
                                    <div class="d-flex align-items-center ps-2 py-2">
                                        <div
                                            style="width: 32px;"
                                            class="d-flex position-relative my-1 align-items-center">
                                            <img style="height: 24px" :src="(eventType.icon_name) ? `/b-icons/${eventType.icon_name}.svg` : '/dealbuzzer_logo_icon_black.svg'" alt="icon"/>
                                        </div>
                                        <p class="text-default mb-0">
                                            {{ eventType?.name }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </v-field>
            <div v-else>
                -> {{ $t('widgets.eventTypeSelect.noEventTypesFound') }}
            </div>
        </div>
    </div>
</template>

<script setup>
const route = useRoute()
const {$apiRoute} = useNuxtApp()
const {t} = useI18n()

const props = defineProps ({
    modelValue: {
        required: true,
        default: () => {}
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    organisationId: {
        required: true,
        type: [Number, null],
    }
})

const emits = defineEmits(['update:data'])

const widget = computed(() => props.modelValue)
const showContent = ref(true)

onMounted(async () => {
    // get eventTypes via function, otherwise the template renders with a delay
    if (useRoute().query?.mode !== 'template') {
        await getEventTypes()
    }


    // select first eventType available, if none is selected
    if (widget.value?.content?.eventTypes.length === 0 && Object.values(eventTypes.value).length > 0) {
        widget.value.content.eventTypes.push(Object.values(eventTypes.value)[0].id);
        emits('update:data')
    }
})

const eventTypes = ref([])
const loading = ref(false)

async function getEventTypes() {
    loading.value = true
    await $larafetch($apiRoute('spa.eventTypes.index'), {
        method: 'GET',
        params: {
            output: 'select',
            filter:[{
                type: 'column',
                operation: 'eq',
                column: 'organisation_id',
                value: props.organisationId
            }]
        }
    }).then(response => {
        eventTypes.value = response
    }).catch(errors => {
        console.log(errors)
        eventTypes.value = []
    }).finally(() => {
        loading.value = false
    })
}

const allEventTypesActive = computed(() => {
    return Object.values(eventTypes.value)?.length === widget.value.content.eventTypes?.length
})

function toggleEventTypesActive() {
    const allSelected = Object.values(eventTypes.value)?.every(eventType => widget.value.content.eventTypes?.includes(eventType.id));
    if (allSelected) {
        // If all items are selected, remove all
        widget.value.content.eventTypes = [];
    } else {
        // If not all items are selected, activate all
        widget.value.content.eventTypes = Object.values(eventTypes.value)?.map(eventType => eventType.id);
    }
    emits('update:data')
}

function toggle(eventTypeId) {
    if (widget.value.content.eventTypes?.includes(eventTypeId)) {
        // if eventType exists in array eventTypes remove eventType from array
        widget.value.content.eventTypes = widget.value.content.eventTypes?.filter(id => id !== eventTypeId)
    } else {
        // if eventType does not exist in array eventTypes add eventType to array
        if (!widget.value.content?.eventTypes) {
            widget.value.content['eventTypes'] = []
        }
        widget.value.content.eventTypes?.push(eventTypeId)
    }
    emits('update:data')
}

function toggleShow() {
    showContent.value = !showContent.value
}
</script>
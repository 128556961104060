<template>
    <div class="d-flex flex-column">
        <p @click="toggleShow()" class="cursor-pointer user-select-none mb-0 h5 fw-bold">
            <font-awesome-icon :icon="(showContent) ? 'caret-down' : 'caret-right'" class="pe-2"/>
            {{ $t('widgets.userSelect.title') }}
        </p>

        <div v-if="showContent" class="d-flex flex-column gap-3 mt-3 pt-2">
            <v-field
                name="userSelection"
                :value="widget?.content.userSelect"
                :formErrors="errors">
                <div
                    v-if="widget?.content"
                    class="form-check d-flex flex-column gap-3"
                    v-for="userSelect in userSelections">
                    <div>
                        <input
                            class="form-check-input"
                            type="radio"
                            :name="'userSelect' + userSelect.value"
                            :id="'userSelect' + userSelect.value"
                            :value="userSelect.value"
                            v-model="widget.content.userSelect"
                            @change="$emit('update:data')">
                        <label class="form-check-label" :for="'userSelect' + userSelect.value" v-html="userSelect.label"></label>
                    </div>

                    <div
                        v-if="userSelect.value === 'selection'"
                        :class="{'d-none' : widget.content.userSelect !== 'selection'}">
                        <div class="d-flex flex-column gap-3">
                            <v-field
                                v-if="Object.values(users)?.length > 0"
                                name="users"
                                :value="widget?.content.users"
                                :formErrors="errors">

                                <div v-if="loading" class="mt-2">
                                    <font-awesome-icon  spin icon="fa-circle-notch" class="me-2"/>
                                    <span>{{ $t('common.loading') }}</span>
                                </div>

                                <!-- users selection -->
                                <div v-else class="form-check d-flex flex-column justify-content-center gap-3">
                                    <div class="mt-2">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="users"
                                            :checked="allUsersActive"
                                            @change="toggleUsersActive()">
                                        <label for="users">
                                            {{ $t('widgets.userSelect.selectAll') }}
                                        </label>
                                    </div>
                                    <ul class="ps-0 list-unstyled">
                                        <li v-for="user in users">
                                            <div class="d-flex align-items-center gap-2">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :name="'user' + user.id"
                                                    :id="'user' + user.id"
                                                    :checked="widget.content.users?.includes(user.id)"
                                                    @change="toggle(user.id)">
                                                <label class="form-check-label" :for="'user' + user.id">
                                                    <div class="d-flex">
                                                        <div
                                                            style="width: 64px;"
                                                            class="d-flex position-relative my-1 align-items-center">
                                                            <profile-image :image="user?.image"
                                                                           :title="user?.firstname"
                                                                           :size="'sm'"
                                                                           :noBorder="true"
                                                                           class="d-flex px-1"/>
                                                        </div>
                                                        <div class="my-2">
                                                            <p class="text-default fw-bold mb-0">
                                                                {{ user?.firstname }} {{ user?.lastname }}
                                                            </p>
                                                            <p class="mb-0 text-muted text-small">
                                                                {{ user?.organisation?.name }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </v-field>
                            <div v-else>
                                -> {{ $t('widgets.userSelect.noMembersFound') }}
                            </div>
                        </div>
                    </div>
                </div>
            </v-field>
        </div>
    </div>
</template>

<script setup>
const route = useRoute()
const {$apiRoute} = useNuxtApp()
const {t} = useI18n()

const props = defineProps ({
    modelValue: {
        required: true,
        default: () => {}
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    organisationId: {
        required: true,
        type: [Number, null],
    }
})

const emits = defineEmits(['update:data'])

const widget = computed(() => props.modelValue)
const showContent = ref(true)

const userSelections = [
    {value: 'all', label: t('widgets.userSelect.all')},
    {value: 'authenticated', label: t('widgets.userSelect.authenticated')}
]

onMounted(() => {
    // get users via function, otherwise the template renders with a delay
    if (useRoute().query?.mode !== 'template') {
        userSelections.push({value: 'selection', label: t('widgets.userSelect.selection')})
        getUsers()
    }
})

const users = ref([])
const loading = ref(false)

function getUsers() {
    if (loading.value) return
    loading.value = true
    $larafetch($apiRoute('spa.users.index'), {
        method: 'GET',
        params: {
            output: 'select',
            filter:[{
                type: 'column',
                operation: 'eq',
                column: 'organisation_id',
                value: props.organisationId
            }]
        }
    }).then(response => {
        users.value = response;
    }).catch(errors => {
        console.log(errors)
        users.value = []
    }).finally(() => {
        loading.value = false

        // Check for invalid user IDs
        const validUserIds = Object.keys(users.value);
        const invalidUserIds = []
        widget.value.content.users.forEach((userId) => {
            if (!validUserIds.includes(userId.toString())) {
                invalidUserIds.push(userId)
            }
        });

        if (invalidUserIds.length > 0) {
            // Remove invalid user IDs from widget.value.content.users
            widget.value.content.users = widget.value.content.users.filter(userId => !invalidUserIds.includes(userId));
        }
    })
}

const allUsersActive = computed(() => {
    return Object.values(users.value)?.length === widget.value.content.users?.length
})

function toggleUsersActive() {
    const allSelected = Object.values(users.value)?.every(user => widget.value.content.users?.includes(user.id));
    if (allSelected) {
        // If all items are selected, remove all
        widget.value.content.users = [];
    } else {
        // If not all items are selected, activate all
        widget.value.content.users = Object.values(users.value)?.map(user => user.id);
    }
    emits('update:data')
}

function toggle(userId) {
    if (widget.value.content.users?.includes(userId)) {
        // if user exists in array users remove user from array
        widget.value.content.users = widget.value.content.users?.filter(id => id !== userId)
    } else {
        // if user does not exist in array users add user to array
        if (!widget.value.content?.users) {
            widget.value.content['users'] = []
        }
        widget.value.content.users?.push(userId)
    }
    emits('update:data')
}

function toggleShow() {
    showContent.value = !showContent.value
}
</script>
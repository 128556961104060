<template>
    <div class="d-flex flex-column gap-4 p-4">
        <base-input-floating
            :label="$t('dashboards.sidebar.settings.name')"
            :rules="{required: false}"
            :formErrors="errors"
            name="name"
            @update:model-value="model.is_dirty = true"
            v-model="model.name">
            <template #help-text>
                <div class="pt-2">
                    <small class="text-muted">{{ $t('dashboards.sidebar.settings.defaultNameInfo') }}</small>
                </div>
            </template>
        </base-input-floating>

        <base-input-floating
            v-if="$can('create', 'dashboardTemplates') && isTemplate"
            :label="$t('dashboards.sidebar.settings.description')"
            :rules="{required: false}"
            :formErrors="errors"
            name="description"
            v-model="model.description"
            @update:model-value="model.is_dirty = true">
        </base-input-floating>

        <ImageUpload
            v-if="$can('create', 'dashboardTemplates') && isTemplate"
            v-model="model.image"
            @update:model-value="model.is_dirty = true"
            class="pb-3"/>

        <IconPicker
            v-if="!isTemplate && !isDefault"
            v-model="model.icon_name"
            @update:model-value="model.is_dirty = true"/>

        <base-checkbox
            v-if="!isDefault"
            v-model="model.is_public"
            @update:model-value="model.is_dirty = true"
            name="publicActive">
            {{ $t('dashboards.sidebar.settings.publicText') }}
        </base-checkbox>

        <base-checkbox
            v-if="!isDefault"
            v-model="model.home_visible"
            @update:model-value="model.is_dirty = true"
            name="homeActive">
            {{ $t('dashboards.sidebar.settings.homeText') }}
        </base-checkbox>

        <SelectLinkedDashboards
            v-if="!isTemplate && !isDefault"
            v-model="model"
            @update:model-value="model.is_dirty = true"
            class="mt-3"/>

        <div v-if="isTemplate"
             class="d-flex flex-column">
            <div class="form-floating">
                <v-field
                    v-model="model.language"
                    name="language"
                    :label="$t('dashboards.sidebar.settings.language')"
                    v-slot="{ field, errors, errorMessage, meta}">
                    <label class="floating-label mb-1" for="interval">
                        {{ $t('dashboards.sidebar.settings.language') }}
                    </label>
                    <select v-model="model.language"
                            @update:model-value="model.is_dirty = true"
                            class="form-control text-muted"
                            id="interval"
                            @change="$emit('update:data')">
                        <option :value="null">
                            {{ $t('dashboards.sidebar.settings.noLanguage') }}
                        </option>
                        <option v-for="locale in locales" :key="locale.code" :value="locale.code">
                            {{ locale.name }}
                        </option>
                    </select>
                    <font-awesome-icon icon="fa-chevron-down" class="position-absolute top-50 end-0"/>
                </v-field>
            </div>
        </div>
    </div>
</template>

<script setup>
import IconPicker from "~/components/pages/eventTypes/IconPicker.vue";
import ImageUpload from "@/components/farbcode-core/Inputs/ImageUpload";
import SelectLinkedDashboards from "~/components/pages/dashboards/SelectLinkedDashboards.vue"
import {useI18n} from "vue-i18n";
import {useDashboardStore} from "@/store/dashboard";
import BaseCheckbox from "~/components/farbcode-core/Inputs/BaseCheckbox.vue";
import BaseInputFloating from "~/components/farbcode-core/Inputs/BaseInputFloating.vue";
import {useAbility} from "@casl/vue";

const dashboardStore = useDashboardStore()
const isTemplate = ref(false)
const isDefault = ref(false)

const { can } = useAbility()
const {locales} = useI18n()

const props = defineProps({
    errors: {
        type: Object,
        default: {},
    }
})

const model = computed({
    get: () => dashboardStore.dashboard,
    set: (newValue) => dashboardStore.dashboard = newValue
})

onMounted(() => {
    if (useRoute().query?.mode === 'template' && can('create', 'dashboardTemplates')) {
        isTemplate.value = true;
    } else if (useRoute().query?.mode === 'default' && can('create', 'dashboardTemplates')) {
        isDefault.value = true;
    }
})
</script>

<style scoped>

</style>
<template>
    <div class="py-3 border border-gray-400 rounded-1">
        <div class="px-3">
            <div class="form-floating ">
                <label class="floating-label" for="linkedDashboard">{{ $t('dashboards.sidebar.settings.groupWith') }}</label>
                <select v-model="selected" name="linkedDashboards" id="linkedDashboards"
                        @change="addLinkedDashboard(selected)"
                        class="form-control cursor-pointer">
                    <option value="null" class="muted" disabled>{{ $t('dashboards.sidebar.settings.chooseDashboard') }}</option>
                    <optgroup :label="$t('dashboards.sidebar.settings.group')" v-if="!dashboardData.group_id" v-for="group in availableGroups">
                        <option v-for="option in availableOptions.filter((option) => option.group_id === group)"  :value="option">
                            {{ option.name }}
                        </option>
                    </optgroup>
                    <option v-for="option in availableOptions.filter((option) => !option.group_id)"  :value="option">
                        {{ option.name }}
                    </option>
                </select>
            </div>
        </div>
        <div :class="{'mt-4' : displayGroupedDashboards.length > 0}">
            <ul class="list-unstyled mb-0">
                <li v-for="dashboard in displayGroupedDashboards" :key="dashboard.id">
                    <div class="d-flex gap-2 align-items-center justify-content-between py-1 hover-bg-gray-100 px-3">
                        <p class="mb-0">{{ dashboard.name }}</p>
                        <small class="cursor-pointer" @click="removeLinkedDashboard(dashboard)">{{ $t('dashboards.sidebar.settings.remove') }}</small>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import {useLinkedDashboardsStore} from "@/store/linkedDashboards";

const linkedDashboardsStore = useLinkedDashboardsStore()

const props = defineProps({
    modelValue: {
        type: Object,
        default: () => {
        }
    },
    identifier: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['update:modelValue'])

onMounted(() => {
    linkedDashboardsStore.fetchDashboards()
})

const dashboardData = computed({
    get: () => props.modelValue,
    set: (newValue) => emit('update:modelValue', newValue)
})
const addedDashboards = computed({
    get: () => linkedDashboardsStore.addedDashboards,
    set: (newValue) => linkedDashboardsStore.addedDashboards = newValue
});
const removedDashboards = computed({
    get: () => linkedDashboardsStore.removedDashboards,
    set: (newValue) => linkedDashboardsStore.removedDashboards = newValue
});
const dashboardsWithGroupId = computed(() => linkedDashboardsStore.dashboardsWithGroupId);
const availableGroups = computed(() => linkedDashboardsStore.dashboardGroups);

const selected = ref(null)

const availableOptions = computed(() => {
    const connectedDashboardIds = connectedDashboards.value.map(dashboard => dashboard.id);

    // Filter available options based on dashboard id not being in connectedDashboardIds or addedDashboards
    const availableOptions = dashboardsWithGroupId.value.filter(dashboard => {
        return (
            dashboard.id !== dashboardData.value.id &&
            !connectedDashboardIds.includes(dashboard.id) &&
            !addedDashboards.value.includes(dashboard.id)
        );
    });

    // If group_id is set, further filter the available options
    if (dashboardData.value.group_id) {
        return availableOptions.filter(dashboard => {
            return !dashboard.group_id || dashboard.group_id === dashboardData.value.group_id;
        });
    }

    return availableOptions;
})

const connectedDashboards = computed(() => {
    // Filter dashboards based on group_id and dashboard id not being in removedDashboards or addedDashboards
    return dashboardsWithGroupId.value.filter(dashboard => {
        return (
            dashboardData.value.group_id &&
            dashboard.group_id === dashboardData.value.group_id &&
            dashboard.id !== dashboardData.value.id &&
            !removedDashboards.value.includes(dashboard.id) &&
            !addedDashboards.value.includes(dashboard.id)
        );
    });
})

const displayGroupedDashboards = computed(() => {
    return connectedDashboards.value.concat( dashboardsWithGroupId.value.filter((addedDashboard) => addedDashboards.value.includes(addedDashboard.id)))
})

function addLinkedDashboard(dashboard) {
    addedDashboards.value.push(dashboard.id)

    // if item is part of removedDashboards, remove it
    const index = removedDashboards.value.indexOf(dashboard.id);
    if (index >= 0) {
        removedDashboards.value.splice(index, 1);
    }

    // if group_id is null, set group_id
    if (!dashboardData.value.group_id) {
        dashboardData.value.group_id = dashboard.group_id
    }

    // reset select
    selected.value = null

    return emit('update:modelValue', props.modelValue)
}

function removeLinkedDashboard(dashboard) {
    removedDashboards.value.push(dashboard.id)

    // if item is part of addedDashboards, remove it
    const index = addedDashboards.value.indexOf(dashboard.id);
    if (index >= 0) {
        addedDashboards.value.splice(index, 1);
    }

    // if there are no dashboards with a group_id, set group_id to null
    if (connectedDashboards.value.length === 0) {
        const addedDashboardsWithGroup = dashboardsWithGroupId.value.filter(dashboard => {
            return addedDashboards.value.includes(dashboard.id) && dashboard.group_id
        })
        if (addedDashboardsWithGroup.length > 0) return
        dashboardData.value.group_id = null
    }

    return emit('update:modelValue', props.modelValue)
}
</script>

<style scoped>

</style>
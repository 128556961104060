<template>
    <div class="d-flex flex-column">
        <p @click="toggleShow()" class="cursor-pointer user-select-none mb-0 h5 fw-bold">
            <font-awesome-icon :icon="(showContent) ? 'caret-down' : 'caret-right'" class="pe-2"/>
            {{ $t('widgets.dataDisplayOption.title') }}
        </p>

        <div v-if="showContent" class="d-flex flex-column gap-3 mt-3 pt-2">
            <v-field
                name="userSelection"
                :value="widget?.content.userSelect"
                :formErrors="errors">
                <div
                    v-if="widget?.content"
                    class="form-check d-flex flex-column gap-3"
                    v-for="dataDisplay in dataDisplayOptions">
                    <div>
                        <input
                            class="form-check-input"
                            type="radio"
                            :name="'dataDisplay' + dataDisplay.value"
                            :id="'dataDisplay' + dataDisplay.value"
                            :value="dataDisplay.value"
                            v-model="widget.content.dataDisplay"
                            @change="$emit('update:data')">
                        <label class="form-check-label" :for="'dataDisplay' + dataDisplay.value"
                               v-html="dataDisplay.label"/>
                    </div>
                </div>
            </v-field>
        </div>
    </div>
</template>

<script setup>
const {t} = useI18n()

const props = defineProps({
    modelValue: {
        required: true,
        default: () => {
        }
    },
    errors: {
        required: false,
        type: Object,
        default: {}
    },
    options: {
        type: Array,
        default: ['sum', 'amount']
    }
})

const emits = defineEmits(['update:data'])

const widget = computed(() => props.modelValue)
const showContent = ref(true)

const availableOptions = [
    {value: 'sum', label: t('widgets.dataDisplayOption.sum')},
    {value: 'amount', label: t('widgets.dataDisplayOption.amount')},
    {value: 'topEvent', label: t('widgets.dataDisplayOption.topEvent')},
]

const dataDisplayOptions = computed(() => {
    return availableOptions.filter(option => props.options.includes(option.value))
})

function toggleShow() {
    showContent.value = !showContent.value
}
</script>